import React, { useEffect } from "react";
import debounce from "lodash-es/debounce";

import { useIsInvalid } from "../FormErrorsContext";
import { GroupOption, SelectOption } from "@admin/utils/types";
import { searchProductCategories as searchProductCategoriesRequest } from "@admin/domain/productCategories/api";
import { title } from "@admin/utils/misc";
import { ProductCategoryModel } from "@admin/domain/productCategories/models/ProductCategoryModel";
import Select from "react-select";

type Props = {
    inputName: string;
    label?: string;
    categoryIds: number[];
    onChange?: (categoryIds: GroupOption[]) => void;
};

export const ProductCategoriesSearch: React.FC<Props> = ({
    categoryIds,
    inputName,
    label,
}) => {
    const [categories, setCategories] = React.useState<GroupOption[]>([]);
    const [allCategories, setAllCategories] = React.useState<GroupOption[]>([]);

    const searchCategories = async (): Promise<void> => {
        const categories = (await searchProductCategoriesRequest()).map(
            (category: ProductCategoryModel): GroupOption => {
                return {
                    label: title(category.texts),
                    options: (category.children || []).map(
                        (child: ProductCategoryModel): SelectOption => ({
                            label: title(child.texts),
                            value: child.id,
                        }),
                    ),
                };
            },
        );

        setCategories(categories);
        setAllCategories(categories);
    };

    useEffect((): void => {
        // noinspection JSIgnoredPromiseFromCall
        searchCategories();

        return;
    }, []);

    const isInvalid = useIsInvalid(inputName);

    const debouncedSearch = debounce((query: string): void => {
        setCategories(
            allCategories.map(
                (category: GroupOption): GroupOption => ({
                    label: category.label,
                    options: category.options.filter(
                        (option: SelectOption): boolean => {
                            return query
                                ? option.label
                                      .toLowerCase()
                                      .includes(query.toLowerCase())
                                : true;
                        },
                    ),
                }),
            ),
        );
    }, 500);

    const defaultOptions: SelectOption[] = [];

    allCategories.forEach((category: GroupOption): void => {
        category.options.forEach((option: SelectOption): void => {
            if (categoryIds.includes(option.value)) {
                defaultOptions.push(option);
            }
        });
    });

    if (allCategories.length === 0) {
        return null;
    }

    return (
        <div className="mb-5">
            {label && <label className="form-label">{label}</label>}

            <Select
                classNames={{
                    control: () =>
                        `form-control p-0${isInvalid ? ` is-invalid` : ``}`,
                }}
                placeholder="Поиск по названию категории"
                onInputChange={debouncedSearch}
                options={categories}
                defaultValue={defaultOptions}
                name={inputName}
                isClearable
                isMulti
            />
        </div>
    );
};

export default ProductCategoriesSearch;
