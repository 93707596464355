import React from "react";
import ReactJson from "react-json-view";

type Props = {
    data: Record<string, any>;
};

export default function JsonView({ data }: Props) {
    return (
        <ReactJson
            src={data}
            displayDataTypes={false}
            displayObjectSize={false}
            collapseStringsAfterLength={false}
            enableClipboard={false}
            name={false}
            collapsed={1}
        />
    );
}
