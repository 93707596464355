import React from "react";

import { saveCustomPage } from "@admin/domain/customPages/api";
import ContentBlocks from "@admin/components/Content/ContentBlocks";
import { PageType } from "@admin/domain/customPages/models/CustomPageModel";
import Editor from "@admin/components/Editor";
import MultiLanguages from "@admin/components/MultiLanguage";
import Select from "@admin/components/Select";
import PublishedAtInput from "@admin/components/PublishedAtInput";
import Input from "@admin/components/Input";
import Switch from "@admin/components/Switch";
import PageSeoParamsEditor from "@admin/components/PageSeoParamsEditor";
import CustomPagesSearch from "@admin/components/CustomPagesSearch";

const LIST_URL = `/admin/custom_pages` as const;

export default function CustomPageEditor() {
    const page = window.CMS.customPage || null;
    const admins = window.CMS.admins || [];
    const customPages = window.CMS.customPages || [];

    if (page) {
        page.textsMap = new Map(
            page.texts.map((value) => [value.language, value]),
        );
    }

    customPages.forEach((parent) => {
        parent.textsMap = new Map(
            parent.texts.map((value) => [value.language, value]),
        );
    });

    const execute = async (body: FormData): Promise<void> => {
        await saveCustomPage(page, body);
    };

    return (
        <Editor backUrl={`${LIST_URL}/${page?.id || ``}`} onSubmit={execute}>
            <Switch
                label="Скрыть / Показать"
                name="is_public"
                defaultChecked={page?.is_public}
            />

            <Input
                label="URL страницы"
                name="path"
                defaultValue={page?.path}
                disabled={true}
            />

            <MultiLanguages
                languageElement={(language) => (
                    <>
                        <input
                            type="hidden"
                            name={`texts[${language}][language]`}
                            defaultValue={language}
                        />

                        <Input
                            label="Заголовок страницы"
                            name={`texts[${language}][title]`}
                            defaultValue={page?.textsMap.get(language)?.title}
                        />
                    </>
                )}
            />

            <Select
                label="Принадлежность"
                name="section"
                defaultValue={page?.section}
                options={[
                    {
                        value: PageType.Public,
                        label: `Интернет-магазин`,
                    },
                    {
                        value: PageType.Private,
                        label: `Личный кабинет`,
                    },
                ]}
                required
            />

            <Select
                label="Автор"
                name="author_id"
                options={admins}
                defaultValue={page?.author?.id}
                required
            />

            <CustomPagesSearch
                label="Родительская страница"
                inputName={`parent_id`}
                pageId={page?.parent?.id}
                pageTitle={page?.parent?.texts?.[0]?.title}
                required={false}
            />

            <PublishedAtInput defaultValue={page?.published_at} />

            <PageSeoParamsEditor />

            <ContentBlocks blocks={page?.blocks} />
        </Editor>
    );
}
