import { Language } from "@admin/domain/languages/Languages";

export const getLanguageField = (
    rows: Record<string, string>[],
    field: string,
    language = Language.Russian,
): string => {
    const row = rows.find(
        (row: Record<string, string>): boolean => row.language === language,
    );

    return row ? row[field] : ``;
};

export const title = (rows: Record<string, string>[]): string =>
    getLanguageField(rows, `title`);
