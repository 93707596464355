import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Alert } from "react-bootstrap";

import {
    initLanguageKeyObject,
    type Language,
} from "@admin/domain/languages/Languages";
import Input from "@admin/components/Input";
import MultiLanguages from "@admin/components/MultiLanguage";
import ImageInput from "@admin/components/ImageInput";
import { AboutPageCompanyHistoryTimelineModel } from "@admin/domain/uniquePages/models/AboutPageModel";
import { v4 as uuid } from "uuid";

type Props = {
    showModal: boolean;
    closeModal: () => void;
    onSubmit: (data: AboutPageCompanyHistoryTimelineModel) => void;
    editingTimeline?: AboutPageCompanyHistoryTimelineModel;
};
type Timeline = AboutPageCompanyHistoryTimelineModel;

export default function AboutPageCompanyHistoryModal(props: Props) {
    const [timeline, setTimeline] = useState<Timeline>(
        props.editingTimeline || {
            id: uuid(),
            file: null,
            text: initLanguageKeyObject(),
            date: initLanguageKeyObject(),
            year: ``,
        },
    );

    const [errors, setErrors] = useState<string[]>([]);

    const submit = () => {
        const newErrors = [];

        if (!timeline.file) {
            newErrors.push(`Изображение обязательно`);
        }

        if (!timeline.year) {
            newErrors.push(`Поле "Год" обязательно для заполнения`);
        }

        if (newErrors.length > 0) {
            setErrors(newErrors);
            return;
        }

        props.onSubmit(timeline);
        props.closeModal();
    };

    return (
        <Modal centered show={props.showModal} onHide={props.closeModal}>
            <Modal.Header>
                <Modal.Title>
                    {props.editingTimeline ? `Обновление` : `Добавление`}
                    {` `}
                    временной отметки
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {errors.length > 0 && (
                    <Alert variant="danger">
                        {errors.map((err, index) => (
                            <div key={index}>{err}</div>
                        ))}
                    </Alert>
                )}

                <div className="d-flex align-items-center justify-content-center">
                    <ImageInput
                        label="Изображение"
                        name=""
                        defaultValue={timeline.file}
                        onChange={(file) => setTimeline({ ...timeline, file })}
                        width="205px"
                        height="205px"
                    />
                </div>

                <Input
                    label="Год"
                    onChange={(event) =>
                        setTimeline({
                            ...timeline,
                            year: event.target.value,
                        })
                    }
                    value={timeline.year}
                />

                <MultiLanguages
                    languageElement={(language: Language) => (
                        <>
                            <Input
                                label="Дата"
                                onChange={(event) => {
                                    setTimeline({
                                        ...timeline,
                                        date: {
                                            ...timeline.date,
                                            [language]: event.target.value,
                                        },
                                    });
                                }}
                                value={timeline.date[language]}
                            />

                            <Input
                                label="Сопроводительный текст для изображения"
                                onChange={(event) => {
                                    setTimeline({
                                        ...timeline,
                                        text: {
                                            ...timeline.text,
                                            [language]: event.target.value,
                                        },
                                    });
                                }}
                                value={timeline.text[language]}
                            />
                        </>
                    )}
                />
            </Modal.Body>

            <Modal.Footer>
                <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={props.closeModal}
                >
                    Закрыть
                </button>
                <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => submit()}
                >
                    {props.editingTimeline ? `Обновить` : `Добавить`}
                </button>
            </Modal.Footer>
        </Modal>
    );
}
