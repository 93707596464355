import React, { useState } from "react";
import MultiLanguages from "@admin/components/MultiLanguage";
import Input from "@admin/components/Input";
import TextArea from "@admin/components/TextArea";
import {
    AboutPageCompanyHistoryTimelineModel,
    AboutPageModel,
} from "@admin/domain/uniquePages/models/AboutPageModel";
import { makePath } from "@admin/utils/makePath";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import AboutPageCompanyHistoryModal from "./AboutPageCompanyHistoryModal";
import { FileModel } from "@admin/domain/files/model/FileModel";
import { type UniquePageProps } from "../../index";
import Sortable from "@admin/components/Sortable";
import { v4 as uuid } from "uuid";

export default function AboutPageCompanyHistory({
    page,
}: UniquePageProps<AboutPageModel>) {
    const timeLinesInit = (
        page.contentObject.company_history__timelines || []
    ).map((value) => {
        return { ...value, id: uuid() };
    });
    const languageContext = useLanguageContext();
    const [showModal, setShowModal] = useState(false);
    const [timelines, setTimelines] = useState(timeLinesInit);
    const [editingTimeline, setEditingTimeline] = useState<
        AboutPageCompanyHistoryTimelineModel | undefined
    >();

    const addHandle = (data: AboutPageCompanyHistoryTimelineModel) => {
        setTimelines((prev) => [
            ...prev,
            { ...data, file: data.file as FileModel },
        ]);
    };

    const editHandle = (data: AboutPageCompanyHistoryTimelineModel) => {
        setTimelines((prev) => {
            const index = prev.findIndex((value) => value.id === data.id);
            prev[index] = data;
            return prev;
        });
    };

    return (
        <div className="mb-10">
            <h4 className="mb-5">История компании Тенториум</h4>

            <MultiLanguages
                languageElement={(language) => (
                    <>
                        <Input
                            label="Заголовок"
                            name={`content[company_history__title][${language}]`}
                            defaultValue={
                                page.contentObject.company_history__title[
                                    language
                                ]
                            }
                        />

                        <TextArea
                            label="Подзаголовок"
                            name={`content[company_history__sub_title][${language}]`}
                            defaultValue={
                                page.contentObject.company_history__sub_title[
                                    language
                                ]
                            }
                        />
                    </>
                )}
            />

            <div className="row row-cols-2 mb-5 g-4">
                <Sortable
                    list={timelines}
                    setList={setTimelines}
                    className="row row-cols-auto"
                >
                    {timelines.map((timeline, index) => (
                        <div key={index} className="col mb-5">
                            <div className="card border h-100">
                                <div className="d-flex justify-content-center mt-3">
                                    <img
                                        className="card-img-top img_placeholder img-thumbnail"
                                        style={{
                                            width: `205px`,
                                            height: `205px`,
                                        }}
                                        src={makePath(timeline.file?.path)}
                                        alt=""
                                    />

                                    <input
                                        type="hidden"
                                        name={`content[company_history__timelines][${index}][file_id]`}
                                        value={timeline.file?.id}
                                    />
                                </div>

                                <div className="card-body">
                                    <div className="card-text mb-3">
                                        <div>
                                            <span className="fw-bolder card-text">
                                                Год:
                                            </span>
                                            {` `}
                                            {timeline.year}
                                            <input
                                                type="hidden"
                                                name={`content[company_history__timelines][${index}][year]`}
                                                value={timeline.year}
                                            />
                                        </div>

                                        <div>
                                            <span className="fw-bolder card-text">
                                                Дата:
                                            </span>
                                            {` `}
                                            {timeline.date[
                                                languageContext.currentLanguage
                                            ] || `Не указана`}
                                            {Object.entries(
                                                languageContext.availableLanguages,
                                            ).map(([, lang]) => (
                                                <input
                                                    key={lang}
                                                    type="hidden"
                                                    name={`content[company_history__timelines][${index}][date][${lang}]`}
                                                    value={timeline.date[lang]}
                                                />
                                            ))}
                                        </div>

                                        <div>
                                            <span className="fw-bolder card-text">
                                                Текст:
                                            </span>
                                            {` `}
                                            {
                                                timeline.text[
                                                    languageContext
                                                        .currentLanguage
                                                ]
                                            }
                                            {Object.entries(
                                                languageContext.availableLanguages,
                                            ).map(([, lang]) => (
                                                <input
                                                    type="hidden"
                                                    key={lang}
                                                    name={`content[company_history__timelines][${index}][text][${lang}]`}
                                                    value={timeline.text[lang]}
                                                />
                                            ))}
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between">
                                        <a
                                            className="card-link cursor-pointer text-success"
                                            onClick={() => {
                                                setEditingTimeline({
                                                    ...timeline,
                                                    index: index,
                                                });
                                                setShowModal(true);
                                            }}
                                        >
                                            Редактировать
                                        </a>

                                        <a
                                            className="card-link cursor-pointer text-danger"
                                            onClick={() => {
                                                setTimelines((prev) =>
                                                    prev.filter(
                                                        (_, prevIndex) =>
                                                            prevIndex !== index,
                                                    ),
                                                );
                                            }}
                                        >
                                            Удалить
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Sortable>
            </div>

            <i
                className="bi bi-plus-square text-primary fs-2hx cursor-pointer ms-3 align-self-center"
                onClick={() => {
                    setEditingTimeline(undefined);
                    setShowModal(true);
                }}
            />

            {showModal && (
                <AboutPageCompanyHistoryModal
                    showModal={showModal}
                    closeModal={() => setShowModal(false)}
                    editingTimeline={editingTimeline}
                    onSubmit={editingTimeline ? editHandle : addHandle}
                />
            )}
        </div>
    );
}
