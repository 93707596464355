import React from "react";

import type { Language } from "@admin/domain/languages/Languages";
import type { ArticleTextModel } from "@admin/domain/articles/models/ArticleTextModel";
import MultiLanguages from "@admin/components/MultiLanguage";
import CheckBox from "@admin/components/CheckBox";
import Input from "@admin/components/Input";
import TextArea from "@admin/components/TextArea";
import PublishedAtInput from "@admin/components/PublishedAtInput";
import Select from "@admin/components/Select";
import VideoInput from "../../components/VideoInput";
import ImageInput from "@admin/components/ImageInput";

const ArticleGeneralData: React.FC = () => {
    const { article, admins } = window.CMS;

    const texts = article?.texts || [];
    const textMap = new Map(
        texts.map((text: ArticleTextModel): [Language, ArticleTextModel] => [
            text.language,
            text,
        ]),
    );

    return (
        <>
            <PublishedAtInput defaultValue={article?.published_at} />

            <Input
                label="Символьный код для URL"
                defaultValue={article?.path}
                name="path"
                disabled={!!article}
            />

            <MultiLanguages
                languageElement={(language: Language): React.JSX.Element => (
                    <>
                        <input
                            type="hidden"
                            name={`texts[${language}][language]`}
                            defaultValue={language}
                        />

                        <Input
                            label="Заголовок страницы"
                            name={`texts[${language}][title]`}
                            defaultValue={textMap.get(language)?.title}
                        />

                        <Input
                            label="ФИО автора"
                            name={`texts[${language}][author_full_name]`}
                            defaultValue={
                                textMap.get(language)?.author_full_name
                            }
                            required
                        />

                        <Input
                            label="Должность автора"
                            name={`texts[${language}][author_achievement]`}
                            defaultValue={
                                textMap.get(language)?.author_achievement
                            }
                            required
                        />

                        <Input
                            label="Страница во Вконтакте (вида https://vk.com/id123456)"
                            name="vk_link"
                            defaultValue={article?.vk_link || ``}
                        />

                        <Input
                            label="Страница в Одноклассниках (вида https://ok.ru/profile/123456)"
                            name="ok_link"
                            defaultValue={article?.ok_link || ``}
                        />

                        <Input
                            label="Страница в Телеграм (вида https://t.me/name)"
                            name="telegram_link"
                            defaultValue={article?.telegram_link || ``}
                        />

                        <Input
                            type="number"
                            min="1"
                            label="Личный номер автора"
                            name="personal_number"
                            defaultValue={article?.personal_number || ``}
                        />

                        <TextArea
                            label="Аннотация"
                            name={`texts[${language}][annotation]`}
                            defaultValue={textMap.get(language)?.annotation}
                        />
                    </>
                )}
            />

            <Input
                type="number"
                label="Примерное время на изучение (в минутах)"
                defaultValue={article?.study_time}
                name="study_time"
            />

            <ImageInput
                label="Изображение-превью"
                name="preview_image_id"
                defaultValue={article?.preview_image}
                height={300}
            />

            <VideoInput
                name="video"
                defaultValue={{
                    file: article?.video?.file,
                    url: article?.video?.url,
                }}
            />

            <ImageInput
                label="Аватар автора"
                name="author_avatar_id"
                defaultValue={article?.author_avatar}
                height={300}
            />

            <Select
                label="Автор"
                options={admins}
                defaultValue={article?.author?.id}
                name="author_id"
                required
            />

            <CheckBox
                label="Скрыть / Показать Комментарии"
                name="is_visible_comments"
                defaultChecked={!article || article.is_visible_comments}
            />

            <CheckBox
                label="Скрыть / Показать блок Читайте так же"
                name="is_visible_other_articles"
                defaultChecked={!article || article.is_visible_other_articles}
            />

            <CheckBox
                label="Скрыть / Показать"
                name="is_public"
                defaultChecked={!article || article.is_public}
            />
        </>
    );
};

export default ArticleGeneralData;
